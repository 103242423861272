#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.mainLayoutContent {
  height: 100vh;
}

.loginForm {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loginFormContainer {
  background: #fff;
  height: 80vh;
}

.initialsBuble {
  background: #f0f2f5;
  color: #1791ff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  font-weight: bold;
  font-size: 12px;
}

.iconContainer {
  text-align: center;
  font-size: 75px;
  font-family: secular;
  text-align: center;
  color: #1791ff;
}

.logoMenuItem {
  opacity: 1;
  order: 0;
  font-family: secular;
  padding-left: 0;
  left: -30px;
  width: 135px;
}

.newMenuItem {
  font-size: 10px !important;
  background-color: #b289ff !important;
  height: auto !important;
  padding: 4px 4px !important;
  line-height: 10px !important;
  top: 5px !important;
}

.btnRequestDemo {
  margin-top: 25px;
  background: #1890ff;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  font-size: 1.2em;
}

.loginRightContainer {
  padding: 50px;
}

.loginMainTitle {
  font-size: 3.6em !important;
  font-weight: 700 !important;
  line-height: 1.1 !important;
  margin-bottom: 0.2em !important;
  margin-top: 0.5em !important;
}

.loginSubTitle {
  font-size: 1.2em !important;
}

.rightContent {
  padding: 0px 100px;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.markedText {
  background-color: #b289ff;
  color: #fff;
  padding: 0 12px;
}

.btnSamuLogin {
  width: 100%;
}

.loginLeftContainer {
  position: relative;
  top: 50%;
  padding: 0 150px;
}

.linkButton {
  font-weight: bold;
}
