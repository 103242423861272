.events {
  margin: 0;
  padding: 0;
  list-style: none;

  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.events > div:hover {
  background: #198fff;
  color: #fff;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.agendaHour {
  background: #f9f9f9;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
}

.addTeamCircle {
  margin-bottom: 16px;
  cursor: pointer;
  float: right;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-size: 40px;
  vertical-align: middle;
  display: inline-block;
  padding: 0;
  line-height: 0;
  padding-bottom: 5px;
}

.filtersContainer {
  display: inline-block;
  width: 22%;
}

.calendarContainer {
  width: 78%;
  float: right;
  padding-left: 24px;
}

.filtersContent {
  background: #f9f9f9;
  padding: 24px;
}

.agendaContentModalList {
  padding: 0;
  margin: 0;
}

.agendaContentModalLine {
  list-style: none;
  padding: 0;
  margin: 0;
}

.agendaContentModalIcon {
  text-align: center;
  vertical-align: middle;
  padding-bottom: 13px;
  padding-right: 11px;
}
.agendaContentModalTitle {
  display: inline-block;
}

.agendaModalContainer {
  padding: 0;
}

.btnOk {
  margin-left: 0;
  margin-right: 8px;
  float: right;
}

.checkboxContainer label {
  width: 100%;
}

.analyticsFilterSelectorContainer {
  margin-bottom: 10px;
  color: #B388FF;
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.analyticsFilterFilterTitle {
  background: #B388FF;
  color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
  top: -30px;
  position: absolute;
}

.analyticsFilterSelect {
  border: 1px solid #B388FF;
  min-width: 200px;
  cursor: pointer !important;
}
.interactionCallTypesFilterSelect {
  min-width: 250px;
}

.totalCallVolumeGraphContainer {
  margin-top: 50px;
}

.analyticsContainerComparing {
  display: inline-block;
  width: 50%;
}

.analyticsContainerSecondary {
}