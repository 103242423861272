.genericExtractorContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.genericExtractorButtonsContainer {
  display: flex;
  gap: 10px;
}
.genericExtractorButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.genericExtractorIconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.optionListContainer {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b388ff;
  padding: 5px;
  border-radius: 5px;
  width: calc(33.33% - 10px);
  box-sizing: border-box;
}

.optionText {
  color: #b388ff !important;
  margin-right: 5px;
  flex-grow: 1;
  text-align: center;
}
.optionIcon {
  color: #b388ff;
}

.optionSelect {
  margin-top: 10px;
}

.optionSelectContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.optionInput {
  max-width: 300px;
}

.optionButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.optionSelectIcon {
  margin-right: 5px;
}

.tab {
  font-weight: 400;
  line-height: "1em";
  padding-bottom: 0;
  margin-bottom: "5px";
}
