.initialsBuble {
  background: #1791ff;
  color: #f0f2f5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  font-weight: bold;
  font-size: 12px;
}