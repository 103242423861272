.teamCircleContainer {
  width: 75px;
  background: blue;
  border-radius: 50%;
  height: 75px;
  text-align: center;
}

.teamCircleItem {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 30px;
  color: #fff;
  text-shadow: 1px 1px 1px black;
}

.topRightSection {
  margin-bottom: 16px;
  cursor: pointer;
  float: right;
  margin-left: auto;
}

.addTeamCircle {
  cursor: pointer;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-size: 40px;
  vertical-align: middle;
  display: inline-block;
  padding: 0;
  line-height: 0;
  padding-bottom: 5px;
}

.initialsBuble {
  background: #1791ff;
  color: #f0f2f5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.settingsTitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem;
  color: #000;
  margin-top: 2.5rem;
  margin-bottom: 45px;
}

.settingsSubtitle {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  margin: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}