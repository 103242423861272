.container {
  background: #fff;
  box-shadow: 0 0 2px #ccc;
  padding: 10px 0 0 15px;
  z-index: 3;
}

.meetingName {
  color: #595d5d !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 20px !important;
  line-height: 1.35 !important;
}

.meetingDate {
  display: inline-block;
  font-size: 11px;
  width: 100%;
  display: block !important;
}

.menuItemLogo {
  font-size: 18px !important;
}

.textContainer {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.meetingDetailHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}