.questionsContent {
  width: 100%;
}

.questionsContent b {
  font-weight: inherit;
}

.tracker-panel .ant-collapse-content {
  background: #fff !important;
}