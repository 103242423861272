.modalContent {
  padding: 30px;
}

.scoreDetails {
  margin-top: 25px;
}

.categoryItem {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  gap: 10px;
  align-items: center;
}

.checkIcon {
  color: green !important;
  font-size: 15px;
}

.closeIcon {
  color: red !important;
  font-size: 15px;
}

.categoryContainer {
  display: flex;
  align-items: center;
  margin-top: 50px;
  gap: 20px;
}

.categoryTitle {
  width: 200px;
}

.progressItem {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.categoryEvaluableScore {
  position: absolute;
  right: 15px;
  background: #b389fe;
  border-radius: 10px;
  padding: 2px 5px;
  font-weight: bold;
  color: #fff;
  font-size: 11px;
}

.evaluableModalPanel {
  margin-top: 10px;
}