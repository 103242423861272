.avatarMeetingDurationContainer {
  position: relative;
}

.avatarMeetingDuration {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #1a90ff;
  color: #fff;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 5px 0 0 0 !important;
}

.timelineItemIconContainer {
  position: relative;
}


.matchItem {
  color: #1a90ff;
  margin-top: 15px;
  overflow: hidden;
}

.matchItemText, .matchItemBadge {
  position: relative;
  float: left;
}

.matchItemText {
  margin-left: 15px;
}

.matchItemTitle {
  margin-bottom: -4px !important;
  color: #000 !important;
}
.matchItemBadge {}

.timelineItemIcon {
  /* position: absolute;
  left: 50%;
  top: 15px;
  background: #fff;
  border-radius: 5px;
  font-size: 10px;
  padding: 3px;
  color: #4ed4ff;
  border: 1px solid #4ed4ff;
  transform: translateX(-50%); */
}
.initialsBuble {
  background: #1791ff;
  color: #f0f2f5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  font-weight: bold;
  font-size: 12px;
}

.seenIconContainer {
  display: inline-block;
  position: absolute;
  right: 15px;
  font-size: 15px;
  bottom: 5px;
  font-weight: 500;
  color: #1791ff;
}

.seenIconContainer span {
  vertical-align: middle;
}

.saveFilterIcon {
  width: 8%;
  background: #fff;
  line-height: 29px !important;
  border: 1px solid #d9d9d9;
  padding-top: 0;
  top: -2px;
  left: 6px;
  position: relative;
  cursor: pointer;
}

.meetingTag {
  border-radius: 5px !important;
  background-color: #eee !important;
  border: none !important;
  color: #666 !important;
}

.callTypeTag {
  border-radius: 5px !important;
  background-color: #B388FF !important;
  border: none !important;
  color: #fff !important;
  font-weight: 600;
}

.samuThumb {
  background: #b388ff;
  height: 100%;
}

.samuThumb img{
  transform: translateY(80%);
  top: 0;
  position: relative;
}

.noPaddingPopover :global(.ant-popover-inner-content) {
  padding: 0;
}