.messageChatContent {
  display: flex;
  flex-direction: column;
  padding: 0 3px;
  padding-top: 3px;
}
.messageChatContentTime {
  font-size: 10px;
  bottom: -5px;
  right: -10px;
}

.sidebarIcon {
  vertical-align: middle;
  color: #b388ff;
  margin-right: 5px;
}

.conversationNotFoundContainer {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conversationNotFoundTitle {
  font-weight: normal;
}

.btnVelocity {
  background: #b389ff;
  padding: 0px 10px;
  border-radius: 14px;
  font-size: 12px;
  margin-left: 8px;
  opacity: 0;
  transition: all 0.3s;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
}

.playing .btnVelocity {
  opacity: 1 !important;
}

.summaryTab {
  margin-top: 20px;
  height: 65vh;
  overflow: auto;
}

.fileContainer {
  padding: 20px;
  background-color: aliceblue;

}

.pdfCard {
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 150px;
  height: 100px;
  padding: 10px;
}

.fileName {
  font-weight: bold;
  font-size: 14px;
}

.pdfIcon {
  font-size: 24px;
  color: #F3603F;
  margin-left: 10px;
}
.textComment {
  display:flex;
  flex-direction: row;
  padding: 5px 10px;
}



