.container {
  height: 100vh;
}

.container > li {
  overflow: visible;
  height: 80px;
  text-align: center;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.container > li span {
  display: block;
}

.menuItemLogo {
  font-size: 18px !important;
}