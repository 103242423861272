.newLibraryCard,
.libraryItem {
  width: 400px;
  margin-top: 16px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.libraryItem {
  border-top: 3px solid #1791ff;
}

.newLibraryCard {
  font-size: 30px;
  text-align: center;
  border: 2px solid #1791ff;
  /* background: #1791ff; */
  font-weight: bold;
  color: #1791ff;
  border-radius: 5px;
}
