.samuScoreItemContainer {
  display: inline-flex;
  align-items: center;
  padding: 0 50px;
}

.title {
  margin-left: 5px;
}

.subtitle {
  color: #1791ff;
  display: block;
}

.percentNumber {
  font-size: 18px;
}