.mediaControlsContainer {
  text-align: center;
  background: #1a90ff;
  border-radius: 15px;
  padding: 10px;
  color: white;
}

.speakerIcon{
  margin: 0px 15px;
  vertical-align: -0.125em;
  cursor: pointer;
}