.tabContainer {
    display: flex;
    align-items: center;
}

.iconTab {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

.sidebarContainer {
    background: #fff !important;
    height: 100%;
    border-top: 1px solid #ddd;
    padding: 30px 20px;
  }