.filters{
  margin-left: auto;
}

.filtersContainer {
  display: flex;
  gap: 10px; /* Adjust spacing between filters */
  flex-wrap: wrap; /* Ensure responsiveness */
}

.alignLeft {
  display: flex;
  justify-content: flex-end;
}

.filterContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #B388FF;
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.filterTitle {
  background: #B388FF;
  color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
  top: -30px;
  position: absolute;
}

.filterSelect {
  border: 1px solid #B388FF;
  min-width: 200px;
  cursor: pointer !important;
}

.tableContainer {
  background: white;
  border-radius: 2px;
}

.coachingTable {
  :global(.ant-table-cell) {
    white-space: nowrap;
  }
}

.nameCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

/* Hacer que la primera columna sea fija */
:global(.ant-table-cell-fix-left) {
  background: white !important;
}

.divider {
  margin: 0 0 24px 0 !important;
  background-color: transparent !important;
  border-bottom: 3px solid #82B1FF !important;
}

.vendorCell {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 0;
}

.avatarContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatarPlaceholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #666;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.vendorName {
  font-weight: 500;
  color: #000;
  line-height: 1.2;
}

.vendorEmail {
  font-size: 12px;
  color: #666;
}
