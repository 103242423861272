.notificationContainer {
  margin: -25px;
  padding: 0 25px;
  cursor: pointer;
  transition: all 1s;
}

.notificationContainer:hover {
  transition: all 1s;
  background: #eee;
}

.thumb img {
  width: 100% !important;
  height: 100% !important;
}