.content {
  transition: 0.5s;
  position: relative;
}

.container {
  background-color: #07befd;
  color: #fff;
  padding: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}