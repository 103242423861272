.btnCompare {
  color: #fff;
  background: #B388FF !important;
  border: 1px solid #B388FF;
  margin-left: 10px;
}

.btnCompare:hover {
  color: #B388FF;
  background: #fff !important;
  border: 1px solid #B388FF;
}