.listContainer {
  background-color: #fff;
  padding: 5px 10px;
}

.headerMeetingName {
  font-size: 15px !important;
  margin-bottom: 0 !important;
  color: #1a91ff;
}

.headerMeetingNameContent {
  display: inline-block;
  padding-left: 10px;
}

.headerMeetingImg {
  vertical-align: super;
  border-radius: 0;
}

.title {
  display: inline-block;
}