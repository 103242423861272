
.loading {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loadingContainer {
  background: #fff;
  height: 100vh;
}