.mediaTimeLineContainer {
  background: #ddd;
  height: 5px;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.mediaTimeLineItem {
  position: absolute;
  left: 0;
  width: 100px;
  height: 5px;
  background: #000;
  border-radius: 18px;
}

.loading {
  width: 100%;
    padding: 50px 0;
}