.callTypeSelectorContainer {
  margin-bottom: 20px;
  color: #B388FF;
}

.callTypeFilterTitle {
  background: #B388FF;
  color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
}

.callTypeSelect {
  border: 1px solid #B388FF;
}