.newLibraryCard,
.libraryItem {
  width: 400px;
  margin-top: 16px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.libraryItemContainer {
  margin-top: 16px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  border-top: none;
  width: 100%;
  background-color: white;
  padding: 24px;
  position: relative;
}

.libraryItemBox {
  max-width: 100%;
}

.libraryHeaderContainer {
  gap: 16px;
}

.appIcon {
  width: 50px;
  margin-right: 1rem;
  height: max-content;
}

.libraryItem {
  border-top: 3px solid #1791ff;
}

.saveButtonContainer {
  margin-top: 15px;
  margin-left: calc(50px + 1rem);
}

@media (max-width: 600px) {
  .saveButtonContainer {
    margin-left: 0rem;
  }
}

.libraryHeaderInfo{
  display: flex;
}
.saveButton {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: block;
  margin-top: 1rem;
  cursor: pointer;
}

.toggleContainer {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;;
}

.toggle {
  gap: 16px;
}

.badge {
  width: 25px;
  position: absolute;
  right: 0;
  top: 10px;
  font-weight: 10px;
}

.newLibraryCard {
  font-size: 30px;
  text-align: center;
  border: 2px solid #1791ff;
  /* background: #1791ff; */
  font-weight: bold;
  color: #1791ff;
  border-radius: 5px;
}
