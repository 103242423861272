.notificationCheckbox {
  margin: 0 !important;
}

.notificationsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.titleTab {
  margin-bottom: 30px !important;
}

.divider {
  margin: 12px 0;
}

.generalSectionsContainer {
  padding: 15px
}
